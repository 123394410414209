import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import CountUp from 'react-countup';
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from 'react-visibility-sensor';
import PopupForm from '../components/popupform'
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    togglePopup();
    typeof window !== "undefined" && window.gtag("event", "click", {   
      'event_category': "Request Demo",
      'event_label': "Demo Request",
      'value': 1 
    })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Sunset Health - Mental Wellness App" />
      <PopupForm isPopupOpen={isPopupOpen} onClickPopup={togglePopup} />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
                <div className="uni-col">
                    <h1 className="uni-h1">Empower your people to focus on what matters</h1>
                    <p className="uni-hero-p">Reduce burnout, improve performance, and remove your blockers to well-being. Personalized digital therapy brought to you by <span className="color-457B9D emp-font-bold">Sunset Health</span></p>
                    <button className="uni-cta-btn btn" onClick={handleClick}>Request a Demo</button>
                </div>
                <img src={require('../../content/assets/hero-content/homepage.svg')} className="uni-hero_img homepage_img" />
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container">
              <div className="emp-stress-wrap">
                <div className="ind-stress">
                  <div className="ind-stress-item bg-fff">
                    <h2>Burnout is everywhere</h2>
                    <p className="uni-p emp-benefits-p">Driven by psychological and environmental factors, burnout is a highly complex presentation of exhaustion, fatigue, anxiety, depression, cynicism, and diminishing sense of self-efficacy</p>
                  </div>
                </div>
                {/* <img src={require('../../content/assets/splash/homepage-burnout.jpg')} className="ind-stress-image" /> */}
                <Img 
                  fluid={data.burnoutImage.childImageSharp.fluid}
                  className="ind-stress-image"
                />
              </div>
              <div className="ind-stats-wrap">
                <div className="ind-stats-item bg-fff">
                  <h2 className="ind-stats-h2 color-457B9D">
                    <CountUp end={70} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    %+
                  </h2>
                  <p className="uni-p ind-stats-p">of employees report burnout impacting their performance</p>
                </div>
                <div className="ind-stats-item bg-fff">
                  <h2 className="ind-stats-h2 color-457B9D">
                    $
                    <CountUp end={500} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <span> Billion</span>
                  </h2>
                  <p className="uni-p ind-stats-p">lost each year in medical and work-related costs</p>
                </div>
                <div className="ind-stats-item bg-fff">
                  <h2 className="ind-stats-h2 color-457B9D">
                    <CountUp end={76} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp> 
                    <span> Million</span>
                  </h2>
                  <p className="uni-p ind-stats-p">people in the US report seeking help for symptoms related burnout</p>
                </div>
              </div>
            </div>
        </section>
        <section className="uni-section">
          <div className="uni-h-wrap user-benefits-h">
              <h2>Our industry leading platform</h2>
              <p className="uni-p text-align-center">On-demand access to personalized therapy, anytime you need it</p>
          </div>
          <div className="uni-container user-benefits flex-horizontal-center-stretch flex-vertical-reverse">
            <div className="uni-col text-box bg-f0f6f9">
              <h4 className="align-left">For individuals:</h4>
              <p className="uni-p align-left">
                Improve your mental wellness and behavioral health
              </p>
              <ul className="emp-user-benefits-list clear-spacing">
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Access your blocker" className="emp-user-benefits_img" />
                  <p>Validated assessments to pinpoint your stressors and stress levels</p>
                </li>
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Focus on what matters" className="emp-user-benefits_img" />
                  <p>Digestible and easy-to-use cognitive behavioral therapy right in your pocket</p>
                </li>
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Take the actions that work" className="emp-user-benefits_img" />
                  <p>Daily insights help you see and understand what’s working</p>
                </li>
              </ul>
              <Link to="/program" className="uni-cta-btn pricing-cta btn">Learn More</Link>
            </div>
            <img src={require('../../content/assets/splash/homepage-individuals.jpg')} className="emp-home_img" />
          </div>
          <div className="uni-container user-benefits flex-horizontal-center-stretch flex-vertical">
            <img src={require('../../content/assets/splash/homepage-employers.jpg')} className="emp-home_img" />
            <div className="uni-col text-box bg-fdf3ee">
              <h4 className="align-left">For corporate leaders:</h4>
              <p className="uni-p align-left">
                Enhance your company culture, improve productivity, and mental resilience
              </p>
              <ul className="emp-user-benefits-list clear-spacing">
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Access your blocker" className="emp-user-benefits_img" />
                  <p>First ever dynamic platform that is fully customizable</p>
                </li>
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Focus on what matters" className="emp-user-benefits_img" />
                  <p>Platform-based employee assistance, analytics, and validated assessments</p>
                </li>
                <li className="emp-user-benefits-item">
                  <img src={require('../../content/assets/icons/check.png')} alt="Take the actions that work" className="emp-user-benefits_img" />
                  <p>Robust anonymous analytic reporting</p>
                </li>
              </ul>
              <Link to="/employers" className="uni-cta-btn pricing-cta btn">Learn More</Link>
            </div>
          </div>
        </section>
        <section className="uni-section border">
            <div className="uni-container">
              <div className="uni-h-wrap">
                  <h2>A personalized therapeutic platform for everyone</h2>
                  <p>Sunset personalizes a journey for each person and learns the links between their habits and feelings.</p>
              </div>
              <div className="home-grid-container">
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/filter.png')} alt="email for general inquiries" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Weekly Assessments</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Identify how isolation and burnout impact you through weekly assessments</p>
                  </div>
                </div>
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/clipboard.svg')} alt="email for partnership" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Personalized Recovery Plan</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Made of daily actions that have been clinically proven to reduce levels of stress and burnout while improving mental resilience</p>
                  </div>
                </div>
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/attitude.png')} alt="email for technical issues" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Mood Tracker</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Track how you feel daily across 15-dimensions of moods to see how your feelings change.</p>
                  </div>
                </div>
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/clipboard.svg')} alt="email for press inquiries" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Daily Check-ins &#38; Goals</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Quick and easy micro-journaling exercises for you to reflect on your progress</p>
                  </div>
                </div>
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/iphone.png')} alt="email for sunset employees" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Coaching</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Daily reminders help guide you through the recovery process, pushing you to stay on track and prepared for the day ahead</p>
                  </div>
                </div>
                <div className="uni-grid-3col">
                  <div className="home-grid-in">
                    <img src={require('../../content/assets/icons/bar-chart.png')} alt="email for sunset employees" className="home-grid-icon" />
                    <h5 className="emp-h5 emp-font-bold color-154a6e">Discover New Insights</h5>
                  </div>
                  <div className="hom-grid-drop">
                    <p>Uncover what actions and exercises are helping you recover the most over time</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container">
              <div className="uni-h-wrap">
                  <h2>Take the first step towards changing your mindset</h2>
              </div>
              <div className="emp-feature-content">
                  <div className="ind-phones-wrap bg-f0f6f9" style={{overflow: "hidden"}}>
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <Spring delay={100} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-phones-item" style={{opacity, transform}}>
                                <img src={require('../../content/assets/splash/Assessment_screen.svg')} alt="Focus on what matters" className="ind-phone_img" />
                                <div className="text-box-hold ind-phone-description">
                                    <h4 className="emp-h4 emp-feature-h4 align-left">Clinically derived assessments</h4>
                                    <p className="emp-p align-left">Use our clinically backed assessments to get a snapshot of your burnout levels and mental wellbeing</p>
                                </div>
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-phones-item" style={{opacity, transform}}>
                              <img src={require('../../content/assets/splash/Home_Screen.svg')} alt="Access your blocker" className="ind-phone_img" />
                              <div className="text-box-hold ind-phone-description">
                                <h4 className="emp-h4 emp-feature-h4 align-left">Your plan to overcome isolation and burnout</h4>
                                <p className="emp-p align-left">Your recovery plan is made of daily actions that help you feel better, personalized just for you</p>
                              </div>
                            </div>
                          )}
                        </Spring>
                        )}
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility>
                      {({ isVisible }) => (
                        <Spring delay={300} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(200px)" }}>
                          {({opacity, transform}) => (
                            <div className="ind-phones-item" style={{opacity, transform}}>
                                <img src={require('../../content/assets/splash/Insights_screen.svg')} alt="Take the actions that work" className="ind-phone_img" />
                                <div className="text-box-hold ind-phone-description">
                                    <h4 className="emp-h4 emp-feature-h4 align-left">Learn what works for you</h4>
                                    <p className="emp-p align-left">Get valuable insights on which actions and exercises made the biggest impact on you</p>
                                </div>
                            </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                  </div>
              </div>
            </div>
        </section>
        <section className="uni-section">
          <div className="uni-container">
              <div className="ind-get-in-touch-wrap">
                  <div className="homepage-get-in-touch-inner">
                    <div className="homepage-get-in-copy bg-f0f6f9">
                        <h3>Ready to start your mental health journey?</h3>
                        <button className="uni-cta-btn btn" onClick={handleClick}>Request a Demo</button>
                    </div>
                    <div className="homepage-get-in-touch_img">
                        <img src={require('../../content/assets/splash/homepage-phone.jpg')} />
                    </div>
                  </div>
              </div>
          </div>
        </section>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    burnoutImage:file(relativePath: { eq: "splash/homepage-burnout.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1220, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
